<template>
	<div id="app">
		<router-view></router-view> <!-- 路由出口 -->
	</div>
</template>

<script>
	export default {
		name: 'App',
		created() {
			if (!localStorage.getItem('loadedBefore')) {
				// 设置已加载过的标记
				localStorage.setItem('loadedBefore', true);
			} else {
			}
		}
	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		background-color: #ffffff;
		overflow-y: auto;
	}

	.custom-backtop {
		width: 45px;
		/* 自定义宽度 */
		height: 45px;
		/* 自定义高度 */
		/* 其他样式属性 */
	}

	.up {
		height: 100%;
		width: 100%;
		font-size: 28px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #F67F21;
	}

	/* 隐藏默认滚动条 */
	::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	/* ::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background-color: #F5F6F7;
		z-index: 999; 
	
	::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: #F5F6F7;
		z-index: 999;
	}
	
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: #FF7C99;
		z-index: 999; 
	} */
</style>